import React, { Component } from 'react'

export default class Contact extends Component {

    render() {

        return(
     
            <div className="flex justify-center items-center image-container mt4">
                <img src='https://i.ibb.co/44nTsB2/R1-04832-0029.jpg' alt="nastya" className="contact-pics mx2" style={{ flexGrow: 1 }}/>
                <div className="contact-text text px2 flex flex-column" style={{flexGrow: 2}}>
                    <p className="mt1 center">
                        Shot over a summer by Arthur’s daughter Tess Cohen and her friend Nastasya Popov, the documentary aims to capture the fun-loving spirit of its star.</p>
                    <p className="center">Premiering in Spring 2019, PICKLE MAN is a finalist for The Horizon Award, presented at the Sundance Film Festival. </p>
                    <p className="center">Please contact the filmmakers with inquiries, comments, and pickling stories at picklemandoc@gmail.com</p>
                    <p className="center">We’d love to hear from you!</p>
                </div>
                <img src='https://i.ibb.co/6NVKC32/12747492-10208341129823452-2518764176259019261-o.jpg' alt="tess" className="contact-pics mx2" style={{ flexGrow: 1 }}/>    
                
            </div>
        )
    }
}
