import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'

import Load from './components/Load'
import Home from './components/Home'
import Donate from './components/Donate'
import Contact from './components/Contact'
import MobileNav from './components/MobileNav'

import Close from './assets/images/closed.svg'
import Facebook from './assets/images/facebook.svg'
import Instagram from './assets/images/instagram.svg'



import Title from './assets/images/title_alpha.png'
import Hamburger from './assets/images/noun_menu_568033.svg'


class App extends Component {

  constructor(){
    super()
    this.state = { show: false, mobile: window.innerWidth <= 60 ? true : false }
  }

  componentDidMount = () => { 
    window.addEventListener('resize', (e) => {
      if (window.innerWidth <= 660) {
        this.setState({ mobile: true })
      }
    })
  }

  handleClick = () => {
    console.log('display nav')
    this.setState({ show: !this.state.show})
  }

  render() {
    return (
      <Fragment>
        <Router>
          <div>
            <nav className="desktop-nav nav">
              {/* <ul className="list-reset flex justify-between items-center px3">

                <div>
                  <li className="p2">
                    <Link to="/" 
                          className="text-decoration-none">
                          <img 
                            src={Title} 
                            alt="logo-png" 
                            width="200"
                            />
                    </Link>
                  </li>
                </div>

                <div className="flex">
                  <li className="p2">
                    <Link to="/about/" 
                          className="text-decoration-none">The Documentary Short</Link>
                  </li>
                  <li className="p2">
                    <Link to="/donate/" 
                          className="text-decoration-none">Donate</Link>
                  </li>
                  <li className="p2">
                    <Link to="/contact/" 
                          className="text-decoration-none">Contact</Link>
                  </li>
                </div>
              </ul> */}

              <ul className="list-reset flex justify-center items-center px3 mr2">
                  <li className="p2">
                    <Link to="/about/"
                      className="caps text-decoration-none">The Documentary Short</Link>
                  </li>
                  <li className="p2">
                    <Link to="/"
                      className="text-decoration-none">
                      <img
                        src={Title}
                        alt="logo-png"
                        width="240"
                      />
                    </Link>
                  </li>
                  <li className="p2">
                    <Link to="/donate/"
                      className="caps text-decoration-none">Donate</Link>
                  </li>
                  <li className="p2">
                    <Link to="/contact/"
                      className="caps text-decoration-none">Contact</Link>
                  </li>
              </ul>
            </nav>

            <div className="mobile-nav flex items-center justify-between m3">
              <img
                src={Title}
                alt="logo-png"
                width="100"  />
              <img 
                src={Hamburger} 
                alt="menu-button"
                width="28"
                onClick={this.handleClick} />
            </div>

            {this.state.show ? 
              <div className="mobile-display-nav fixed top-0 left-0 right-0 bottom-0">
              <ul className="my1 mr3 list-reset">
         
                <img
                  src={Close}
                  alt="close-icon"
                  width="30"
                  className="right"
                  onClick={this.handleClick} />
                <div className="flex flex-column mt3 pt3 ml2">
                  <li className="p2 mt2">
                    <Link to="/about/"
                      className="dark-green text-decoration-none"
                      onClick={this.handleClick}> The Documentary Short</Link>
                  </li>
                  <li className="p2">
                    <Link to="/donate/"
                      onClick={this.handleClick}
                      className="dark-green text-decoration-none">Donate</Link>
                  </li>
                  <li className="p2">
                    <Link to="/contact/"
                      onClick={this.handleClick}
                      className="dark-green text-decoration-none">Contact</Link>
                  </li>
                </div>
              </ul>
              <div className="flex items-center my1 ml2 mt3">
                <a href="https://www.facebook.com/pickals" target="_blank" rel="noopener noreferrer">
                  <img
                    src={Facebook}
                    alt="facebook-icon"
                    className="social-icons mx2"
                    width="30" />
                </a>

                <a href="https://www.instagram.com/pick.als/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={Instagram}
                    alt="insta-icon"
                    className="social-icons mx2"
                    width="30" />
                </a>

              </div>
              </div>
            
            : null}

            <Route exact path="/" component={Load} />
            <Route path="/about" component={Home} />
            <Route path="/donate/" component={Donate} />
            <Route path="/contact/" component={Contact} />
          </div>
        </Router>
      </Fragment>
    );
  }
}

export default App;
